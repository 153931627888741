import axios from 'axios'

//live

// export const baseConfig = axios.create({
//   baseURL: 'https://api.pacesswellness.com/api/v1/',
// })

// Demo

export const baseConfig = axios.create({
  baseURL: 'https://pacess.talrop.works/api/v1/',
})

// local

// export const baseConfig = axios.create({
//   baseURL: 'http://192.168.1.101:9000/api/v1/',
// })
// export const userConfig = axios.create({
//   baseURL: 'http://192.168.1.101:9000/api/v1/',
// })
